@import '../../../../../scss/_trashie.scss';

.TakeBackBagsListItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--trashie-ui-faded-black-30);
    background: var(--trashie-ui-crisp-white);
    overflow: hidden;
    width: calc(100% * (1/4) - 13px);
    cursor: pointer;
    height: 100%;

    &:hover {
        transform: scale(1.025);
    }

    &__imgContainer {
        display: flex;
        width: 100%;
        min-height: 172px;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 150px;
        -moz-background-size: 150px;
        -o-background-size: 150px;
        background-size: 150px;
    }

    &__details {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px;
        gap: 8px;
        height: 100%;

        &--label {
            font-size: 14px;
            font-weight: 700;
            line-height: 100%;
            color: var(--trashie-ui-faded-black-100);
            text-align: left;
            text-transform: none;
        }

        &--activateButton {
            padding: 8px 24px;
            border-radius: 10px;
            border: 1px solid var(--trashie-ui-faded-black-100);
            background: var(--trashie-ui-crisp-white);
            width: auto;

            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
            color: var(--trashie-ui-faded-black-100);

            &:hover {
                color: var(--trashie-ui-faded-black-100);
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .TakeBackBagsListItem {
        width: calc(100% * (1/4) - 14px);
    }
}

@media screen and (max-width: 768px) {
    .TakeBackBagsListItem {
        width: calc(100% * (1/2) - 8px);
        height: min-content;

        &__details {
            height: min-content;
        }
    }
}