@import "../../../../../scss/trashie";

.GenericBanner {
    display: flex;
    justify-content: space-between;
    padding: 0px 64px;
    width: 100%;
    border-radius: 8px;
    background: var(--trashie-ui-thrifty-sky);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

    &__container {
        display: flex;
        width: 426px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 64px 0px;
        text-align: left;
        text-transform: none;

        font-family: "GT Maru";
        line-height: 150%;
    }

    &__title {
        font-size: 22px;
        font-weight: 700;
    }

    &__description {
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        color: var(--trashie-ui-faded-black-80);
    }

    &__cta {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--trashie-primary-code-blue);

        text-decoration: none;

        &:hover {
            color: var(--trashie-primary-code-blue);
            text-decoration: underline;
        }
    }

    &__img {
        display: flex;
        align-items: center;
        margin: 20px;
        padding-right: 22px;
        width: 230px;

        img {
            width: 100%;
            filter: drop-shadow(10px 10px 20px hsla(0, 0%, 0%, 0.2));
        }
    }
}

@media screen and (max-width: 1020px) {
    .GenericBanner {
        &__img {
            margin: 20px 0px;
            width: 200px;
        }
    }
}

@media screen and (max-width: 768px) {
    .GenericBanner {
        padding: 0px 16px;
        gap: 8px;

        &__container {
            padding: 16px 0px;
            gap: 8px;
        }

        &__title {
            font-size: 18px;
        }

        &__description {
            font-size: 11px;
            line-height: 120%;
        }

        &__img {
            margin: 20px 0px;
            width: 116px;
            padding-right: 0px;
            min-width: 116px
        }

        &__cta {
            font-size: 14px;
        }
    }
}