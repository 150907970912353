@import '../../../../scss/_trashie.scss';
.main {
    display: flex;
    width: 100%;
    padding: 64px;
    background: var(--trashie-ui-faded-black-10);

    .container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
        gap: 32px;
        max-width: 960px;

        .listContainer{
            width: 100%;
        }
    }
}

@media screen and (max-width: 1020px) {
    .main{
        padding: 32px 16px;
    }
}