.TakeBackBagsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 20px;
    background: var(--trashie-ui-faded-black-10);
    padding: 0;
    align-items: flex-start;
    gap: 16px;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .TakeBackBagsList {
        gap: 8px 16px;
    }
}