@import '../../../../scss/_trashie.scss';

.main {
    display: block;
    padding: 80px 32px;
    background-color: var(--ui-crisp-white);

    .container {
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        max-width: 1312px;

        .logo {
            align-self: flex-start;
            text-decoration: none;

            .img {
                width: 91px;
                vertical-align: middle;
                display: inline-block;
            }
        }

        .columnsSection {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .footerColumn {
                display: flex;
                flex-direction: column;
                max-width: 500px;

                &:last-child {
                    padding-right: 20%;
                }

                .columnTitle {
                    color: var(--trashie-ui-faded-black-100);
                    font-family: GT Maru;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    text-transform: uppercase;
                    margin-bottom: 24px;
                }

                .linkList {
                    list-style-type: none;
                    text-align: left;
                    padding-left: 0;

                    li {
                        margin-bottom: 16px;
                    }

                    .link {
                        color: var(--trashie-ui-faded-black-100);
                        font-family: GT Maru;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .resources {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-top: 80px;
            border-top: 1px solid var(--ui-faded-black);
            padding-top: 32px;

            .list {
                display: flex;
                align-items: flex-start;
                gap: 24px;

                .link {
                    color: var(--trashie-ui-faded-black-100);
                    font-family: GT Maru;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    text-transform: uppercase;
                }

                .copy {
                    color: var(--trashie-ui-faded-black-100);
                    font-family: GT Maru;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    text-transform: uppercase;
                }

                .link {
                    cursor: pointer;
                    font-size: 14px;
                    text-decoration: underline;

                    &:active,
                    &:hover {
                        outline: 0;
                    }
                }
            }

            .socialNetworks {
                display: flex;
                align-items: center;
                gap: 12px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .main {
        position: fixed;
        bottom: -1px;
        left: 0px;
        right: 0px;
        padding: 0;
        z-index: 9;

        .container {
            padding: 14px 0 20px;
            align-items: normal;
            box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.05);

            .logo {
                margin: 0 0 5% 0;
            }

            .columnsSection {
                margin-top: 0;
                flex-direction: column;

                .footerColumn {
                    margin: 0;

                    .logo {
                        margin: 0 0 40px;
                    }

                    .columnTitle {
                        margin-bottom: 20px;
                    }

                    .linkList {
                        margin-bottom: 32px;
                    }
                }

                .subscribeEmailForm {
                    flex-direction: column;
                    gap: 10px;

                    >div,
                    .subscribeButton {
                        width: 90% !important;
                    }
                }
            }

            .resources {
                flex-direction: column;
                margin-top: 0;

                .list {
                    flex-direction: column;
                    align-self: flex-start;
                    order: 2;

                    .link,
                    .copy {
                        font-size: 14px;
                    }

                    .copy {
                        order: 10;
                        margin-top: 16px;
                    }
                }

                .socialNetworks {
                    align-self: flex-start;
                    order: 1;
                    margin-bottom: 32px;
                }
            }
        }
    }
}